import axios from 'axios';
import { AppConfig } from '../../config';

const API = axios.create({
    baseURL: AppConfig.baseURL
});


API.interceptors.request.use((config) => {
    if(!config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json';
    }
    return config;
});

API.interceptors.response.use(
    (response) => {
        if (response.data) {
            return response.data;
        }
        else {
            return response.data ? response.data : {};
        }
    }, (error) => {
        console.log(error);
        if(error.response) {
            throw error.response;
        }
        throw error;
    }
);


export default API;