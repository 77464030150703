import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { BlogService } from '../service/blog.service';
import { Blog } from '../model/blog';
import DisplayPicture from '../components/display-picture';
import { AppConfig } from '../../config';
import { connect } from 'react-redux';
import { UserData } from '../redux/types';

interface Props {
    UserData?: UserData;
}
const Home = (props: Props) => {
    let isLoaded = false;
    const [availableBlogs, setAvailableBlogs] = useState<Blog[]>();
    useEffect(() => {
        console.log('props ', props);
        
        if (!isLoaded && props.UserData?.client_id) {
            loadAllBlogs();
        }
        // isLoaded = true;
    }, [props.UserData]);

    const loadAllBlogs = async () => {
        const result = await BlogService.getAll(props.UserData?.client_id as string);
        if (result && result.length) {
            result.forEach((element, index) => {
                if (element.poster_url) {
                    element.poster_url_display = `${AppConfig.mediaBaseUrl}/${element.poster_url}`;
                }
                switch (index) {
                    case 0:
                        element.position_css = 'item1'
                        break;
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        element.position_css = `item${index + 1}`
                        break;
                    default:
                        break;
                }
            });
        }
        setAvailableBlogs(result);
    }

    return (
        <div>
            {props.UserData?.client_id &&
                <>
                    {props.UserData?.can_write && <div className='shadow-bottom-reg pv-10 bg-dark clr-white flex-box align center justify content end' style={{ height: 60 }}>
                        <NavLink className={'clr-white mr-10 btn main outline rounded'} to={'/editor/0'}>Write New</NavLink>
                    </div>}
                    <div className="m-container pv-10 blog-layout" style={{ paddingBottom: 250 }}>
                        {availableBlogs?.map(el => <NavLink to={`blog/${el.blog_url}`} key={el._id} className={`${el.position_css} `}>
                            <div className='border-box mb-10 ph-30 pv-10 ph-md-10 blog-item'>
                                <div className="flex-box align center mb-10">
                                    <DisplayPicture width={50} height={50} first_name={el.user_name || ''} last_name='' profile_pic={el.user_profile_pic}></DisplayPicture>
                                    <div className='ml-10 clr-reg  flex-box column'>
                                        <b className='mb-5'>{el.user_name}</b>
                                        <small>
                                            {`${new Date(el.created_dt).getDate()}-${new Date().getMonth()+1}-${new Date(el.created_dt).getFullYear()}`}
                                        </small>
                                    </div>
                                </div>
                                <div className="flex-box content-item mb-10">
                                    {el.poster_url_display && <img style={{ width: 300, height: 150 }} src={el.poster_url_display} className='w-full cover rounded mr-10' />}
                                    <h2 className='clr-reg'>{el.title}</h2>
                                </div>
                                {
                                    el.tags && el.tags.length > 0 && <div className="">
                                        {el.tags.map(tagEl => <span className='clr-light mr-20 inline-block'>#{tagEl}</span>)}
                                    </div>
                                }
                            </div>
                        </NavLink>)}
                    </div>
                </>
            }
            {
                !props.UserData?.client_id &&
                <span className="clr-err flex-box justify center ph-30">
                    Client information not available. Please configure your client information
                </span>
            }
        </div>
    )
}


const mapStateToProps = (state: any) => ({
    UserData: state.user
});
export default connect(mapStateToProps)(Home);