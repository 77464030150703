import React, { useEffect, useRef, useState } from 'react'
import { BlogEditorElement } from './types';

interface Props {
    data: BlogEditorElement;
    onChangeElements: (elements: BlogEditorElement) => void;
    mode: 'editor' | 'viewer';
}
export const TextElement = (props: Props) => {
    const [elementData, setElementData] = useState<BlogEditorElement>();
    const [ckEditorRef, setCkEditorRef] = useState<any>();
    const textContainer = useRef(null);
    let isAlreadyLoaded = false;
    const [readViewContent, setReadViewContent] = useState('');
    const readModeViewer = useRef(null);

    useEffect(() => {
        if (readViewContent && readModeViewer && readModeViewer.current) {
            const viewerDiv = readModeViewer.current as HTMLDivElement;
            if (viewerDiv) {
                viewerDiv.innerHTML = readViewContent;
            }
        }
    }, [readViewContent])

    useEffect(() => {
        if (!isAlreadyLoaded) {
            setElementData(props.data);
            const InlineEditor = (window as any).InlineEditor;
            InlineEditor
                .create(document.querySelector(`#ckeditor_${props.data.id}`))
                .then(async (editor: any) => {
                    setCkEditorRef(editor);
                    editor.setData(props.data?.content || 'Click here to update the content');
                    if (props.mode === 'viewer') {
                        const editorElement = document.querySelector(`#ckeditor_${props.data.id}`);
                        if (editorElement) {
                            setReadViewContent(editorElement.innerHTML);
                        }
                        await editor.destroy();
                    }
                })
                .catch((error: any) => {
                    console.error(error);
                }
                );
            // if (props.mode === 'editor') {

            //     const InlineEditor = (window as any).InlineEditor;
            //     InlineEditor
            //         .create(document.querySelector(`#ckeditor_${props.data.id}`))
            //         .then((editor: any) => {
            //             setCkEditorRef(editor);
            //             editor.setData(props.data?.content || 'Click here to update the content');
            //         })
            //         .catch((error: any) => {
            //             console.error(error);
            //         }
            //         );
            // } else if (textContainer.current) {
            //     const htmlElement = textContainer.current as HTMLDivElement;
            //     if (htmlElement) {
            //         htmlElement.innerHTML = props.data.content as string;
            //         setTimeout(() => {
            //             updateBlogLinks();
            //         }, 1000);
            //     }
            // }
        }
        isAlreadyLoaded = true;
    }, []);

    const updateBlogLinks = () => {
        if (textContainer.current) {
            const htmlElement = textContainer.current as HTMLDivElement;
            if (htmlElement) {
                const htmlLinks = htmlElement.getElementsByTagName('a');
                for (let index = 0; index < htmlLinks.length; index++) {
                    const element = htmlLinks[index];
                    if (element) {
                        element.target = '_blank';
                    }
                }
            }
        }
    }

    const onElementBlur = () => {
        if (props.onChangeElements && elementData && ckEditorRef) {
            const updatedElement: BlogEditorElement = { ...elementData, content: ckEditorRef.getData() };
            setElementData(updatedElement);
            props.onChangeElements(updatedElement);
        }
    }

    return (
        <>
            <div id={`ckeditor_${props.data.id}`} onBlur={onElementBlur} ref={textContainer} style={{
                display: readViewContent ? 'none' : 'block'
            }}>
                <span style={{ textAlign: 'center' }}>Click to update the content</span>
            </div>
            {
                readViewContent &&
                <div className='blog-element-viewer' ref={readModeViewer}></div>
            }
        </>
    )
}
