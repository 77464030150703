import React from 'react';
// import './App.css';
import './assets/styles/css/styles.css';
import './assets/styles/css/blog-builder.css';
import './assets/styles/third-party/font-awesome/css/all.min.css';
import { Provider } from 'react-redux';
import { ReduxStore } from './app/redux/store';
import BlogRoutes from './app/pages';

function App() {
  return (
    <div className="App mr-css">
      <Provider store={ReduxStore}>
        <BlogRoutes></BlogRoutes>
      </Provider>
    </div>
  );
}

export default App;
