import React, { useEffect } from 'react'
import { BlogEditorElement } from './types'
import { ImageElement } from './image-element';
import { TextElement } from './text-element';

interface Props {
    data: BlogEditorElement;
    onChangeElement: (element: BlogEditorElement) => void;
    mode: 'editor' | 'viewer';
}

export const BlogElement = (props: Props) => {

    return (
        <div className='blog-element'>
            {props.data.type === 'img' && <ImageElement onUpdate={props.onChangeElement} data={props.data} />}
            {props.data.type === 'text' && <TextElement mode={props.mode} onChangeElements={props.onChangeElement} data={props.data} />}
        </div>
    )
}
