import React, { KeyboardEventHandler, SyntheticEvent, useEffect, useState } from 'react'
import FormBuilder, { FormBuilderData } from '../components/form-builder'
import { BlogService } from '../service/blog.service';
import { connect } from 'react-redux';
import { UserData } from '../redux/types';
import { useNavigate, useParams } from 'react-router-dom';
import { PresignedUrlRes } from '../model/presigned-url.res';
import { MediaUploadReq } from '../model/media.upload.req';
import BlogBuilder from '../components/blog-builder';
import CreatableSelect from 'react-select/creatable';
import { BlogEditorElement } from '../components/blog-builder/types';
import { Blog } from '../model/blog';
import { AppConfig } from '../../config';

interface Props {
    UserData?: UserData;
}
const components = {
    DropdownIndicator: null,
};

interface TagOption {
    readonly label: string;
    readonly value: string;
}

const BlogEditor = (props: Props) => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [blogFormData, setBlogFormData] = useState<FormBuilderData>({
        errors: {},
        isValid: false,
        value: {}
    });
    const [posterPreview, setPosterPreview] = useState<string>();
    const [posterFile, setPosterFile] = useState<File>();
    const [selecteBlogId, setSelecteBlogId] = useState('');
    const [contentElements, setContentElements] = useState<BlogEditorElement[]>();
    const [blogData, setBlogData] = useState<{ title: string, poster_url: string }>();
    let { blog_id } = useParams<{ blog_id: string }>();
    const navigation = useNavigate();
    const [isBlogLoaded, setIsBlogLoaded] = useState(false);
    const [showInvlidClient, setShowInvlidClient] = useState(false);
    let isLoading = false;

    useEffect(() => {
        if (!isLoading) {
            loadBlogData();
        }
        isLoading = true;
    }, []);

    const loadBlogData = async () => {
        if (blog_id && blog_id !== '0') {
            setSelecteBlogId(blog_id);
            const data = await BlogService.getBlogById(blog_id);
            if (data) {
                if (data.poster_url) {
                    setPosterPreview(`${AppConfig.mediaBaseUrl}/${data.poster_url}`)
                }
                if (Array.isArray(data.content)) {
                    for (let index = 0; index < data.content.length; index++) {
                        const element: BlogEditorElement = data.content[index];
                        if (element.type === 'img') {
                            if (typeof element.content === 'string') {
                                if (!element.content.startsWith('http')) {
                                    element.content = `${AppConfig.mediaBaseUrl}/${element.content}`;
                                }
                            }
                        }
                    }
                    setContentElements([...data.content]);
                }
                setBlogData({ title: data.title, poster_url: data.poster_url});
                setBlogFormData({
                    isValid: true,
                    errors: null,
                    value: {
                        title: data.title,
                        poster_url: data.poster_url
                    }
                })
                if (data.tags && data.tags.length) {
                    const tagVals: any[] = data.tags.map((el: string) => {
                        return { label: el, value: el }
                    }
                    );
                    setSelectedTags(tagVals);
                }
            }
        }
        setIsBlogLoaded(true);
    }

    const handleInput = (data: FormBuilderData) => {
        setBlogFormData(data);
    }

    const onSaveForm1 = async () => {
        console.log('sorm ', blogFormData, selectedTags);

    }
    const onSaveForm = async () => {
        try {
            setIsFormSubmitted(true);
            let poster_url = '';
            if (props.UserData && blogFormData.isValid) {
                const { user_name, user_id, user_profile_pic, client_id } = props.UserData;
                let blogId = selecteBlogId;
                const preparedData: Blog = {
                    ...blogFormData.value,
                    user_name,
                    user_id,
                    user_profile_pic,
                    poster_url,
                    client_id,
                    tags: selectedTags.map(el => el.label)
                }
                if (!blogId) {
                    blogId = await BlogService.create(preparedData);
                    setSelecteBlogId(blogId);
                }else if (blogData) {
                    preparedData.poster_url = blogData.poster_url;
                }

                if (posterFile) {
                    const payloadData: MediaUploadReq = {
                        mediaUploadType: `${blogId}/blog-poster`,
                        fileName: posterFile?.name as string
                    }
                    const presignedPosterUrl = await BlogService.getPosterUploadUrl(payloadData);
                    await BlogService.uploadMediaToUrl(presignedPosterUrl?.url, posterFile, posterFile?.type as string);
                    poster_url = presignedPosterUrl.path;
                    preparedData.poster_url = poster_url;
                }

                if (contentElements) {
                    // upload all images to server
                    for (let index = 0; index < contentElements.length; index++) {
                        const element = contentElements[index];
                        if (element.type === 'img' && typeof element.content === 'object') {
                            const payloadData: MediaUploadReq = {
                                mediaUploadType: `${blogId}`,
                                fileName: element.content?.name as string
                            }
                            const presignedImgUrl = await BlogService.getPosterUploadUrl(payloadData);
                            await BlogService.uploadMediaToUrl(presignedImgUrl?.url, element.content, element.content?.type as string);
                            element.content = presignedImgUrl.path;
                        }
                    }
                    preparedData.content = contentElements;
                }
                if (contentElements || poster_url) {
                    await BlogService.update(blogId, preparedData);
                }
                navigation('/');
            }
        } catch (error: any) {
            if (error.status === 414) {
                setShowInvlidClient(true);
            }
        }
    }

    const onChangePoster = (event: SyntheticEvent) => {
        console.log('event ', event);
        const inputElement = event.target as HTMLInputElement;
        if (inputElement && inputElement.files) {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                if (fileReader.result) {
                    setPosterPreview(fileReader.result as string);
                }
            };
            fileReader.readAsDataURL(inputElement.files[0]);
            setPosterFile(inputElement.files[0]);
        }
    }

    const onUpdateBlogEditor = (elements: BlogEditorElement[]) => {
        setContentElements(elements);
    }

    const createTagOption = (label: string) => ({
        label,
        value: label,
    });

    const [inputValue, setTagInputValue] = React.useState<any>('');
    const [selectedTags, setSelectedTags] = React.useState<readonly TagOption[]>([]);

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setSelectedTags((prev) => {
                    const updatedTags = [...prev];
                    const detectedTag = updatedTags.find(el => el.label === inputValue);
                    if (!detectedTag) {
                        updatedTags.push(createTagOption(inputValue));
                    }
                    return updatedTags;
                });
                setTagInputValue('');
                event.preventDefault();
        }
    };

    return (
        <div className='m-container'>
            {
                showInvlidClient && <div className='clr-err'>
                    Please configure your client details
                </div>
            }
            {!showInvlidClient && <>
                <div className="flex-box between align center">
                    <h2>New blog</h2>
                    <button className='main' onClick={onSaveForm}>Save</button>
                </div>
                <FormBuilder onUpdate={handleInput} showValidations={isFormSubmitted}>
                    <form action="">
                        <div className="shadow-bottom-reg"></div>
                        <div className="input-group">
                            <label htmlFor="title" className='required'>Blog title</label>
                            <input defaultValue={blogData?.title} type="text" name="title" id="title" placeholder="Eg: My first journey to the center of the earth" data-validate-required="Please enter game name" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="tag-selection">Tags</label>
                            {/* <CreatableSelect isMulti isClearable name='tags'></CreatableSelect> */}
                            <CreatableSelect
                                defaultValue={{ value: 'hi', label: 'hi' }}
                                components={components}
                                inputValue={inputValue}
                                isClearable
                                isMulti
                                menuIsOpen={false}
                                onChange={(newValue) => setSelectedTags(newValue)}
                                onInputChange={(newValue) => setTagInputValue(newValue)}
                                onKeyDown={handleKeyDown}
                                placeholder="Type something and press enter..."
                                value={selectedTags}
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="poster_input">
                                <div className={`${posterPreview ? '' : 'border-box bg-light'} flex-box justify center align center rounded`} style={{ height: 200 }}>
                                    {!posterPreview && <span className='absolute btn main rounded'>Upload poster</span>}
                                    {posterPreview && <>
                                        <span className='absolute btn main rounded'>Change poster</span>
                                        <img className='w-full cover' src={posterPreview} alt="" />
                                    </>}
                                </div>
                            </label>
                        </div>
                        {/* <div className="input-group">
                        <label htmlFor="content">Content</label>
                        <textarea name="content" id="content" placeholder="Eg: This is the awesome place where i have enjoyed a lot in my life" rows={20} />
                    </div> */}
                    </form>
                </FormBuilder>
            </>}
            {isBlogLoaded && !showInvlidClient && <BlogBuilder type='editor' elements={contentElements} onChangeElements={onUpdateBlogEditor}></BlogBuilder>}
            <input type="file" name="title" id="poster_input" style={{ display: 'none' }} onChange={onChangePoster} accept='image/*' />
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    UserData: state.user
});

export default connect(mapStateToProps)(BlogEditor);