import React, { useEffect, useState } from 'react'
import { Blog } from '../model/blog';
import { BlogService } from '../service/blog.service';
import { NavLink, useParams } from 'react-router-dom';
import DisplayPicture from '../components/display-picture';
import BlogBuilder from '../components/blog-builder';
import { connect } from 'react-redux';
import { UserData } from '../redux/types';
import { BlogEditorElement } from '../components/blog-builder/types';
import { AppConfig } from '../../config';

interface Props {
  UserData?: UserData;
}
const BlogView = (props: Props) => {
  const [blogData, setBlogData] = useState<Blog>();
  const [isEditing, setIsEditing] = useState(false);
  let { blog_title } = useParams<{ blog_title: string }>();
  let isLoading = false;

  useEffect(() => {
    if (!isLoading) {
      loadBlogData();
    }
    isLoading = true;
  }, []);

  const loadBlogData = async () => {
    if (blog_title) {
      const data = await BlogService.getBlogByTitle(blog_title);
      if (Array.isArray(data.content)) {
        for (let index = 0; index < data.content.length; index++) {
          const element: BlogEditorElement = data.content[index];
          if (element.type === 'img') {
            if (typeof element.content === 'string') {
              if (!element.content.startsWith('http')) {
                element.content = `${AppConfig.mediaBaseUrl}/${element.content}`;
              }
            }
          }
        }
      }
      setBlogData(data);
    }
  }
  return (
    <>
      {props.UserData?.can_write && <div className='shadow-bottom-reg pv-10 bg-dark clr-white flex-box align center justify content end' style={{ height: 60 }}>
        <NavLink className={'clr-white mr-10 btn main outline rounded'} to={`/editor/${blogData?._id}`}>Edit</NavLink>
      </div>}
      <div className='m-container mt-10' style={{ paddingBottom: 200 }}>
        {
          blogData && <>
            <div className="flex-box align center">
              <DisplayPicture width={50} height={50} first_name={blogData.user_name || ''} last_name='' profile_pic={blogData.user_profile_pic}></DisplayPicture>
              <div className='ml-10 clr-reg  flex-box column'>
                <b className='mb-5'>{blogData.user_name}</b>
                <small>
                  {`${new Date(blogData.created_dt).getDate()}-${new Date().getMonth() + 1}-${new Date(blogData.created_dt).getFullYear()}`}
                </small>
              </div>
            </div>
            <h2>{blogData.title}</h2>
            <div className="mb-10">
              {blogData.tags && blogData.tags.map(tag => <span className='clr-light mr-20 inline-block'>#{tag}</span>)}
            </div>
            <div style={{ textAlign: 'center' }} className='bg-dark'>
              {blogData.poster_url && <img style={{ height: 300 }} src={`${AppConfig.mediaBaseUrl}/${blogData.poster_url}`}></img>}
            </div>
            <BlogBuilder elements={blogData.content} type='viewer'></BlogBuilder>
          </>
        }
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  UserData: state.user
});
export default connect(mapStateToProps)(BlogView);
