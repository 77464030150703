import { BlogActionType, BlogState } from "./types";

const InitialState = {
    user: null
};
export const BlogReducer = (state = InitialState, action: any) => {
    switch (action.type) {
        case BlogActionType.USER_DATA:
            return { ...state, user: action.data }
            break;

        default:
            return state;
            break;
    }

}