export enum BlogActionType {
    USER_DATA = 'UserData',
}

export interface UserData {
    user_name: string;
    user_profile_pic: string;
    user_id: string;
    can_write: boolean;
    client_id: string;
}

export interface BlogState {
    user: UserData;
}