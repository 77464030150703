import React, { useEffect, useState } from 'react'
import { Resources } from '../../utility/resources';
import { BlogEditorElement, ToolbarAction } from './types';
import { BlogElement } from './blog-element';
import { v4 } from 'uuid';

interface Props {
    type: 'editor' | 'viewer';
    onChangeElements?: (elements: BlogEditorElement[]) => void;
    elements?: BlogEditorElement[]
}
const BlogBuilder = (props: Props) => {
    const [blogElements, setBlogElements] = useState<BlogEditorElement[]>([]);

    useEffect(() => {
        console.log('propsssssss ', props);
        
        if (props.elements) {
            setBlogElements(props.elements);
        }
    }, []);

    const addBlogElement = (index: number) => {
        const previousElements = [...blogElements];
        const newElement: BlogEditorElement = {
            content: '',
            type: 'placeholder',
            id: v4()
        };
        previousElements.splice(index, 0, newElement);
        setBlogElements(previousElements);
        if (props.onChangeElements) {
            props.onChangeElements(previousElements);
        }
    }

    const onChangeElementType = (index: number, type: number) => {
        const previousElements = [...blogElements];
        switch (type) {
            case 1:
                previousElements[index].type = 'img';
                break;

            case 2:
                previousElements[index].type = 'text';
                break;
            default:
                break;
        }
        setBlogElements(previousElements);
        if (props.onChangeElements) {
            props.onChangeElements(previousElements);
        }
    }

    const onChangeElement = (element: BlogEditorElement) => {
        const previousElements = [...blogElements];
        let elementIndex = previousElements.findIndex(el => el.id === element.id);
        if (elementIndex >= 0) {
            previousElements.splice(elementIndex, 1, element);
        }
        console.log('element ', previousElements, elementIndex);
        if (props.onChangeElements) {
            props.onChangeElements(previousElements);
        }
        setBlogElements(previousElements);
    }

    const onToolAction = (action: ToolbarAction, index: number) => {
        const previousElements = [...blogElements];
        switch (action) {
            case ToolbarAction.DELETE:
                previousElements.splice(index, 1);
                break;
        }
        setBlogElements(previousElements);
    }

    return (
        <div className='blog-builder blog-view'>
            {
                blogElements.map((element, index) => <div key={index} className='mb-10 block-editor-element'>
                    {props.type === 'editor' && <div className="editor-toolbar">
                        <div className="toolbar-container">
                            <i className='fa fa-trash editor-tool' onClick={() => onToolAction(ToolbarAction.DELETE, index)}></i>
                        </div>
                    </div>}
                    {props.type === 'editor' && element.type === 'placeholder' && <div className="tools-container">
                        <i onClick={() => onChangeElementType(index, 1)} className='tool-element fa far fa-image'></i>
                        <i onClick={() => onChangeElementType(index, 2)} className='tool-element fa fa-font'></i>
                    </div>}
                    {element.type !== 'placeholder' && <BlogElement mode={props.type} onChangeElement={onChangeElement} key={index} data={element}></BlogElement>}
                    {props.type === 'editor' && <div className='add-element' onClick={() => addBlogElement(index + 1)}>
                        <span></span>
                        <button >Click to add content</button>
                        <span></span>
                    </div>}
                </div>)
            }
            {blogElements.length < 1 && props.type === 'editor' && <button className='mt-10' onClick={() => addBlogElement(0)}>Click to add content</button>}
        </div>
    )
}

export default BlogBuilder;