import { Blog } from "../model/blog";
import { MediaUploadReq } from "../model/media.upload.req";
import { PresignedUrlRes } from "../model/presigned-url.res";
import API from "../utility/axios";

export class BlogService {
    static async create(data: Blog): Promise<string> {
        return API.post('/blog', data);
    }

    static async update(id: string, data: Blog): Promise<string> {
        return API.put(`/blog/${id}`, data);
    }

    static async getAll(client: string): Promise<Blog[]> {
        return API.get(`/blog/list/${client}`);
    }

    static async getBlogByTitle(title: string): Promise<Blog> {
        return API.get(`/blog/${title}`);
    }

    static async getBlogById(id: string): Promise<Blog> {
        return API.get(`/blog/content/${id}`);
    }

    static async getPosterUploadUrl(data: MediaUploadReq): Promise<PresignedUrlRes> {
        return API.post(`/media/upload-url`, data);
    }

    static uploadMediaToUrl(url: string, data: any, type: string): Promise<void> {
        return API.put(url, data, {
            headers: {
                'Content-Type': type
            },
            // transformRequest: [(data) => data],
        });
    }
}