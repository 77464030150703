import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './home'
import BlogEditor from './blog-editor'
import BlogView from './blog-view'
import { connect } from 'react-redux'
import { BlogActionType } from '../redux/types'

interface Props {
    updateUserData?: (data: any) => void;
}
const BlogRoutes = (props: Props) => {
    useEffect(() => {
        const url = new URL(window.location.href);
        const user_name = url.searchParams.get('name');
        const user_profile_pic = url.searchParams.get('profile_pic');
        const user_id = url.searchParams.get('mapped_id');
        const can_write = url.searchParams.get('write');
        const client_id = url.searchParams.get('client');
        const userData = {
            user_name, user_profile_pic, user_id, can_write: can_write==='true', client_id
        };
        if (props.updateUserData) {
            props.updateUserData(userData);
        }
    }, [])

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' Component={Home}></Route>
                <Route path='/editor/:blog_id' Component={BlogEditor}></Route>
                <Route path='/blog/:blog_title' Component={BlogView}></Route>
            </Routes>
        </BrowserRouter>
    )
}

const mapStateToProps = (state: any) => ({
});
const mapDispatchToProps = (dispatch: any) => ({
    updateUserData: (data: any) => dispatch({ data, type: BlogActionType.USER_DATA })
})
export default connect(mapStateToProps, mapDispatchToProps)(BlogRoutes);